import { MouseEvent, useEffect, useState } from 'react';
import Image from 'next/legacy/image';
import { Cover } from 'components/Cover';
import { Button } from 'components/Button';
import { ModalProps } from 'types';
import { DynamicInput } from '../Inputs';
import { NextIcon } from '../NextImage';

import cx from 'classnames';
import styles from './Modal.module.scss';

export const Modal = ({
  actionButton,
  background,
  cancelButton,
  child,
  cutContent,
  description,
  descriptionShape,
  empty,
  fitChild,
  hideZeroRadioOption,
  iconSrs,
  inputProps,
  logo,
  dropHorizontalPadding,
  dropBottomPadding,
  onCoverClick,
  radioProps,
  selfDestruction,
  sendButton,
  size,
  title,
  titleShape,
  withCrossIcon,
}: ModalProps) => {
  const [option, setOption] = useState(0);
  const changeIndex = (index: any) => {
    setOption(index);
  };
  const [inputData, setInputData] = useState(sendButton?.initialValue);

  useEffect(() => {
    if (selfDestruction) {
      setTimeout(selfDestruction.callback, selfDestruction.timeout || 5000);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onInputChange = (field: any, value: any) => {
    setInputData({ [field]: value });
  };

  const buttonAction = radioProps && radioProps.length > 0 ? radioProps[option].action : actionButton?.action;

  const handleCrossIconClick = (e: MouseEvent<any>) => {
    if (cancelButton) {
      cancelButton.action(e);
    }
    if (onCoverClick && !cancelButton) {
      onCoverClick(e);
    }
  };

  const renderDescription = () => {
    if (description?.includes('\n')) {
      return description.split('\n').map((fragment, index) => <p key={index}>{fragment}</p>);
    }
    return <p>{description}</p>;
  };

  return (
    <div className={styles.container}>
      <Cover onCoverClick={onCoverClick} />
      <div
        className={cx(styles.modal, {
          [styles.emptyModal]: empty,
          [styles.largeModal]: size === 'large',
          [styles.fitChild]: fitChild,
          [styles.cutContent]: cutContent,
          [styles.dropHorizontalPadding]: dropHorizontalPadding,
          [styles.dropBottomPadding]: dropBottomPadding,
        })}
        style={background ? { backgroundImage: background, backgroundPosition: 'top', backgroundSize: 'cover' } : {}}
      >
        {!empty ? (
          <>
            {(iconSrs || title || description || withCrossIcon) && (
              <div className={styles.content}>
                {logo && logo}
                {iconSrs && <NextIcon alt="Icon" size={40} src={iconSrs} customClass={styles.icon} />}
                {title && (
                  <div
                    className={cx(styles.title, styles[titleShape || ''], { [styles.noTitleMargin]: !withCrossIcon })}
                  >
                    {title}
                  </div>
                )}
                {description && (
                  <div className={cx(styles.description, styles[descriptionShape || ''])}>{renderDescription()}</div>
                )}
                {withCrossIcon && (
                  <div className={styles.crossIcon} onClick={handleCrossIconClick}>
                    <Image alt="Close" width={24} height={24} src="/icons/development/XDark.svg" />
                  </div>
                )}
              </div>
            )}
            {sendButton && inputProps && (
              <DynamicInput
                id={inputProps.fieldName}
                onChange={onInputChange}
                placeholder={inputProps.placeholder}
                fieldName={inputProps.fieldName}
                value={inputProps.initialValue}
              />
            )}
            {radioProps && radioProps.length > 0 && (
              <div className={styles.radiocheck}>
                {radioProps.map((element, index) => {
                  if (hideZeroRadioOption && !index) return null;
                  const radioId = index + 'radio';
                  const { caption } = element;
                  return (
                    <p className={styles.radioItem} key={caption}>
                      <input
                        className={styles.radio}
                        defaultChecked={index === option}
                        type="radio"
                        id={radioId}
                        name="radio-group"
                      />
                      <label onClick={() => changeIndex(index)} htmlFor={radioId}>
                        {caption}
                      </label>
                    </p>
                  );
                })}
              </div>
            )}

            {child && child}

            <div className={styles.buttons}>
              {cancelButton && (
                <Button
                  buttonType={cancelButton.buttonType}
                  action={cancelButton.action}
                  caption={cancelButton.caption}
                />
              )}
              {actionButton && (
                <Button
                  buttonType={actionButton.buttonType}
                  caption={actionButton.caption}
                  action={buttonAction || (() => {})}
                  disabled={actionButton.disabled}
                />
              )}
              {sendButton && (
                <Button
                  buttonType={sendButton.buttonType}
                  caption={sendButton.caption}
                  action={() => {
                    sendButton.action(inputData);
                  }}
                />
              )}
            </div>
          </>
        ) : (
          child
        )}
      </div>
    </div>
  );
};
