import { ButtonProps } from 'types';
import Image from 'next/legacy/image';
import { Spinner } from 'components/Spinner';

import cx from 'classnames';
import styles from './Button.module.scss';
import type from '@rc-component/async-validator/es/rule/type';

export const Button = ({
  action,
  buttonType = 'blue',
  buttonHeight = '',
  caption,
  className,
  disabled = false,
  fit = true,
  iconSrc,
  iconOnly = false,
  id,
  shape = 'wide',
  withoutBorder,
  withSpinner = false,
  largeIcon,
  isBoldText,
  btnNativeProps = {},
}: ButtonProps) => {
  return (
    <button
      {...btnNativeProps}
      id={id}
      className={cx(
        styles.default_button,
        styles[`${buttonType}`],
        styles[`${buttonHeight}`],
        styles[`${shape}`],
        {
          [styles.fit]: iconSrc && fit,
          [styles.iconOnly]: iconOnly,
          [styles.largeIcon]: largeIcon,
          [styles.withoutBorder]: withoutBorder,
        },
        className,
      )}
      onClick={action}
      disabled={disabled || withSpinner}
    >
      {withSpinner && <Spinner className={styles.buttonSpinner} />}
      {iconSrc ? <Image alt="Plus Circle" width={largeIcon ? 32 : 24} height={largeIcon ? 32 : 24} src={iconSrc} /> : null}
      {caption ? <p className={cx(styles.content, { [styles.indent]: iconSrc, [styles.boldText]: isBoldText })}>{caption}</p> : null}
    </button>
  );
};
